import styled, { createGlobalStyle } from 'styled-components'

const App = () => {
  return (
    <Container>
      <GlobalStyled />
      <Text>Matheus Figueiredo</Text>
      <footer>
        <Text>
          &copy; 2024 Matheus Figueiredo. Todos os direitos reservados. CNPJ:
          44.079.160/0001-43 | Site:
          <a href='http://www.seusite.com.br'>
            https://matheusfigueiredo.dev.br/
          </a>
        </Text>
      </footer>
    </Container>
  )
}

const GlobalStyled = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    background-color: #000;
    text-align: center;
  }
  a {
    color: #fff
  }
`
const Text = styled.p`
  color: #fff;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`

export default App
